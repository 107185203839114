import { useState, useEffect } from "react";
import { IonRow, IonCol } from "@ionic/react";
import { PageTitle } from "../Components";
import { API_URL } from "../config/constants";
import axios from "axios";

export const Video = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const video = await axios.get(`${API_URL}/video`);
        setData(video.data);
      } catch (e) {
        console.error("Error fetching videos:", e.message);
      }
    };

    fetchVideos();
  }, []);

  return (
    <div id="VideoPage" className="video-page">
      <PageTitle pageTitle={"VIDEOS"} />
      <div className="flex-center">
        <IonRow className="video-container">
          {data &&
            data.map((video, index) => (
              <IonCol size="auto" key={index} className="frame-col">
                <iframe
                  key={index}
                  className="shadow-img"
                  src={video?.src}
                  title={"YouTube video player"}
                  frame-border="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
                  allowFullScreen
                />
                {/* <img src={thumbnailUrl} alt="Video Thumbnail" /> */}
                &nbsp;
                <p>{video?.name}</p>
              </IonCol>
            ))}
        </IonRow>
      </div>
    </div>
  );
};
