import React from "react";
import { IonCol } from "@ionic/react";

export const SoundcloudPlayer = (props) => {
  const { isMobile } = props;
  const frameWidth = isMobile ? "250px" : "550px";

  return (
    <IonCol className="sc-col">
      <iframe
        className="sc-frame"
        title="sc-frame"
        width={frameWidth}
        height="20"
        scrolling="no"
        frameBorder="no"
        // allow="autoplay"
        src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1979265983&color=%23ff5500&inverse=true&auto_play=false&show_user=true"
      ></iframe>
    </IonCol>
  );
};
